'use client'

import * as CheckboxWrapper from '@radix-ui/react-checkbox'
import { cva } from 'class-variance-authority'
import { forwardRef, MouseEventHandler, ReactNode, useEffect, useState } from 'react'
import { CheckFill } from '~/core/ui/FillIcons'
import { cn, getDisabledSelectedCondition } from '~/core/ui/utils'

const checkboxWrapperVariants = cva('relative', {
  variants: {
    variant: {
      onlyText: 'flex items-center',
      textWithDescription: 'inline-flex'
    },
    size: {
      md: 'space-x-3',
      sm: 'space-x-2'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    }
  },
  defaultVariants: {
    variant: 'onlyText',
    size: 'md',
    disabled: 'default'
  }
})

const checkboxVariants = cva('flex items-center justify-center rounded', {
  variants: {
    variant: {
      onlyText: '',
      textWithDescription: 'mt-[3px]'
    },
    size: {
      md: 'h-5 w-5 min-w-[20px] min-h-[20px]',
      sm: 'h-4 w-4 min-w-[16px] min-h-[16px]'
    },
    disabled: {
      default: '',
      disabled: 'pointer-events-none'
    },
    checkedCondition: {
      noDisabledSelected:
        'border border-solid focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-800',
      noDisabledWithSelected:
        'focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 bg-primary-400',
      disabledNoSelect:
        'border border-solid focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800',
      disabledWithSelect:
        'focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 bg-primary-200 dark:bg-primary-800'
    }
  },
  defaultVariants: {
    variant: 'onlyText',
    size: 'md',
    disabled: 'default',
    checkedCondition: 'noDisabledSelected'
  }
})

interface CheckboxProps {
  id?: string
  size?: 'sm' | 'md'
  text?: string | ReactNode
  supportingText?: string
  description?: string

  iconOnly?: boolean
  required?: boolean
  isChecked?: boolean | 'indeterminate'
  isDisabled?: boolean
  onCheckedChange?: (e: { target: { checked: boolean } }) => void
  className?: string
  checkboxClassName?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxWrapper.Root>,
  CheckboxProps
>((props, ref) => {
  const {
    id = 'check-box',
    size = 'md',
    text = '',
    supportingText = '',
    description = '',

    iconOnly = false,
    required = false,
    isChecked = false,
    isDisabled = false,
    onCheckedChange = undefined,
    className = '',
    checkboxClassName = '',
    onClick
  } = props
  const [checkedState, setCheckedChange] = useState<boolean | 'indeterminate'>(
    isChecked
  )
  useEffect(() => {
    setCheckedChange(isChecked)
  }, [isChecked])

  const renderedIcon = () => {
    return (
      <CheckboxWrapper.Root
        ref={ref}
        className={cn(
          checkboxVariants({
            size,
            variant: text && !description ? 'onlyText' : 'textWithDescription',
            disabled: isDisabled ? 'disabled' : 'default',
            checkedCondition: getDisabledSelectedCondition({
              isDisabled,
              isSelected: checkedState === 'indeterminate' ? true : checkedState
            }),
            className
          }),
          (checkedState === 'indeterminate' ? true : checkedState) ? checkboxClassName : ''
        )}
        checked={checkedState}
        onClick={onClick}
        onCheckedChange={(checked: boolean) => {
          setCheckedChange(checked)
          if (onCheckedChange) {
            const e = { target: { checked } }
            onCheckedChange(e)
          }
        }}
        disabled={isDisabled}
        required={required}
        id={id}>
        <CheckboxWrapper.Indicator>
          {checkedState === 'indeterminate' && (
            <div
              className={`${
                size === 'md' ? 'w-[12.5px]' : 'w-[10px]'
              } h-[2px] max-h-[2px] rounded bg-white ${
                isDisabled ? 'dark:bg-gray-400' : ''
              }`}
            />
          )}
          {checkedState === true && (
            <CheckFill
              size={size}
              className={
                isDisabled ? 'fill-white dark:fill-gray-400' : 'fill-white'
              }
            />
          )}
        </CheckboxWrapper.Indicator>
      </CheckboxWrapper.Root>
    )
  }

  if (iconOnly) {
    return renderedIcon()
  }

  const fontSizeLabel = size === 'md' ? 'text-base' : 'text-sm'
  return (
    <div
      className={cn(
        checkboxWrapperVariants({
          size,
          variant: text && !description ? 'onlyText' : 'textWithDescription',
          disabled: isDisabled ? 'disabled' : 'default',
          className
        })
      )}>
      {renderedIcon()}

      {text && !description ? (
        <div className="flex items-center space-x-1">
          <span className={`${fontSizeLabel} text-gray-700 dark:text-gray-300`}>
            {text}
          </span>
          {supportingText ? (
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {supportingText}
            </p>
          ) : null}
        </div>
      ) : null}

      {text && description ? (
        <div className="space-y-1">
          {text ? (
            <div className="flex items-center space-x-1">
              <span
                className={`${fontSizeLabel} font-medium text-gray-900 dark:text-gray-200`}>
                {text}
              </span>

              {supportingText ? (
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {supportingText}
                </span>
              ) : null}
            </div>
          ) : null}
          {description ? (
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export { Checkbox }
export type { CheckboxProps }
